.default-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        font-family: $avenir-next;
        color: $dark;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 600;
        letter-spacing: 0.15px;
    }
    h2,
    h3,
    h4,
    h5,
    ul>li,
    ol>li {
        margin-bottom: 10px;
    }
    h1 {
        margin-bottom: 15px;
        font-size: 24px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 16px;
    }
    p {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.44px;
        strong {
            font-weight: 600;
        }
    }
    ul,
    ol {
        li {
            strong {
                font-weight: 600;
            }
            &::marker {
                font-weight: 600;
            }
            ol {
                li {
                    &::marker {
                        font-weight: 400;
                    }
                }
            }
        }
    }
    a {
        text-decoration: underline;
        color: $dark;
        cursor: pointer;
        transition: 0.2s color ease-in-out;
        &:hover {
            color: $green;
        }
    }
}
