.el-tabs {
    $local-light-gray: #f5f5f5;
    $block-name: &;
    &--wide {
        #{$block-name} {
            &__header {
                background-color: $local-light-gray;
                margin: 0 -15px 15px;
                padding: 0 15px;
                @media all and (min-width: $m){
                    margin: 0 -30px 15px;
                    padding: 0 30px;
                }
            }
            &__nav {
                &-wrap {
                    background-color: $local-light-gray;
                    &:after {
                        background-color: $local-light-gray;
                    }
                    &.is-scrollable {
                        padding: 0 36px !important;
                    }
                }
                &-scroll {}
                &-prev,
                &-next {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    height: 48px;
                    font-size: 16px;
                    line-height: 48px;
                }
            }
            &__item {
                height: 48px;
                padding: 0 10px;
                line-height: 48px;
                text-transform: uppercase;
                &.is-active {
                    color: $indigo;
                }
            }
        }
    }
}
