.el-select {
    .el-input {
        .el-select__caret {
            color: black;
        }
    }
    &--filter {
        &:hover:not(.el-select--disabled){
            .el-input__wrapper {
                box-shadow: unset;
            }
        }
        .el-select {
            &__tags {
                width: auto !important;
                flex-grow: unset;
                max-width: 140px !important;
                padding-left: 20px;
                .el-select-tags-wrapper {
                    margin: 0;
                }
                .el-tag {
                    &--info {
                        background-color: $indigo;
                    }
                }
                .el-select__input {
                    &:focus {
                        &::placeholder {
                            opacity: 0;
                        }
                    }
                    &::placeholder {
                        transition: 0.2s opacity ease-in-out;
                    }
                }
            }
        }
        .el-input {
            width: auto;
            flex-grow: unset;
            &__wrapper {
                width: auto;
                padding-left: 25px;
                flex-grow: 0;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: calc(50% - 7px);
                    right: auto;
                    bottom: auto;
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00033 6.17801V12.5C5.00034 12.5853 5.02214 12.6691 5.06368 12.7436C5.10522 12.818 5.16511 12.8806 5.23766 12.9254C5.31021 12.9702 5.39301 12.9957 5.47819 12.9995C5.56337 13.0033 5.6481 12.9852 5.72433 12.947L8.72433 11.447C8.80728 11.4054 8.87703 11.3416 8.92577 11.2627C8.97451 11.1837 9.00033 11.0928 9.00033 11V6.17801L13.3873 0.817014C13.429 0.766192 13.4602 0.707669 13.4792 0.644785C13.4982 0.581901 13.5046 0.515889 13.4981 0.450516C13.4916 0.385144 13.4723 0.321692 13.4413 0.263783C13.4103 0.205874 13.3682 0.154643 13.3173 0.113014C13.2665 0.0713847 13.208 0.0401735 13.1451 0.0211618C13.0822 0.00215018 13.0162 -0.00428946 12.9508 0.00221065C12.8855 0.00871075 12.822 0.0280233 12.7641 0.0590456C12.7062 0.0900678 12.655 0.132192 12.6133 0.183014L8.11333 5.68301C8.04017 5.77245 8.00024 5.88446 8.00033 6.00001V10.691L6.00033 11.691V6.00001C6.00043 5.88446 5.9605 5.77245 5.88733 5.68301L2.05533 1.00001H10.0003C10.1329 1.00001 10.2601 0.947335 10.3539 0.853567C10.4477 0.759799 10.5003 0.632622 10.5003 0.500014C10.5003 0.367406 10.4477 0.240229 10.3539 0.146461C10.2601 0.0526924 10.1329 1.38572e-05 10.0003 1.38572e-05H1.00033C0.905613 -4.93343e-05 0.812823 0.0267943 0.732767 0.0774193C0.65271 0.128044 0.58868 0.200368 0.548131 0.28597C0.507583 0.371573 0.492184 0.466933 0.503727 0.560947C0.51527 0.654962 0.553281 0.743763 0.613334 0.817014L5.00033 6.17801Z' fill='black'/%3E%3C/svg%3E");
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                &.is-focus {
                    box-shadow: unset !important;
                }
            }
            &__inner {
                width: 100%;
                flex-grow: unset;
                height: 24px;
                border-width: unset;
                border-color: unset;
                border-radius: unset;
                border-style: unset;
                padding: 0;
                font-size: 12px;
                line-height: 2;
                color: $dark-middle;
                font-weight: 600;
                background-color: unset;
                &::placeholder {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    color: $dark-middle;
                }
                &:focus {
                    &::placeholder {
                        font-size: unset;
                        transform: unset;
                    }
                }
            }
            &__suffix {
                display: none;
            }
            &.is-focus{
                .el-input__wrapper {
                    box-shadow: unset !important;
                }
            }
        }
    }
}
