.el-popper {
    &--cart-product-block {
        max-width: 320px;
        padding: 15px;
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.25);
        .el-popconfirm {
            &__main {
                align-items: flex-start;
                font-size: 16px;
            }
            &__action {
                .el-button {
                    border-radius: 18px;
                }
            }
        }
    }
}
