.lesson {
    margin-top: 10px;
    $block-name: &;
    &__btn-section {
        padding: 20px 0 15px;
        text-align: center;
    }
    &--loading {
        padding: 30px 0 0;
        #{$block-name} {
            &__text {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 600;
            }
            &__link-back {
                color: $blue;
                cursor: pointer;
                transition: 0.2s color ease-in-out;
                &:hover {
                    color: $green;
                }
                &-icon {
                    margin-right: 5px;
                }
            }
        }
    }
}
