.user-details {
    .form__divider {
        margin-bottom: 40px;
        .form__divider-text {
            margin-bottom: 15px;
            line-height: 1.4;
            letter-spacing: 0.15px;
            color: $dark;
        }
    }

    .date-picker {
      width: 100% !important;
      height: auto;
    }

    .el-overlay {
        z-index: 9999;
    }
}
