.module-block {
    $local-light-gray: #e7e7e7;
    $local-light-indigo: #b5b7df;
    $local-grey: #a6a6a6;
    $block-name: &;
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: default;
    &__image {
        position: relative;
        z-index: 4;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 84px;
        height: 84px;
        margin-right: 21px;
        border-radius: 50%;
        border: 4px solid $local-light-gray;
        background-color: $white;
        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
        }
        &:before {
            z-index: 2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 76px;
            height: 76px;
            background-color: $white;
        }
        &:after {
            z-index: 3;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
            width: 66px;
            height: 66px;
            background-color: $local-light-indigo;
        }

        &-wrap {
            position: relative;
            z-index: 4;
            width: 54px;
            height: 54px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__check-wrap {
        position: absolute;
        z-index: 5;
        right: -6px;
        bottom: -2px;
        width: 28px;
        height: 28px;
        border: 2px solid $white;
        border-radius: 50%;
        background-color: $white;
    }
    &__check {
        font-size: 24px;
        color: $green;
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $local-grey;
    }
    &__description {
        font-size: 15px;
        font-weight: 400;
        color: $gray;
    }
    &--middle-size {
        #{$block-name}__image {
            width: 61px;
            height: 61px;
            margin-right: 18px;
            border-color: $light-gray;
            &:before {
                width: 53px;
                height: 53px;
            }
            &:after {
                width: 43px;
                height: 43px;
                background-color: $indigo;
            }
            &-wrap {
                width: 31px;
                height: 31px;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }
    &--vertical {
        flex-direction: column;
        #{$block-name}__image {
            margin: 0 0 10px;
        }
    }

    &.active {
        cursor: pointer;
        #{$block-name}__image {
            border-color: $light-gray;
            &:after {
                background-color: $indigo;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }
    &.done {
        #{$block-name}__image {
            border-color: $indigo;
            &:after {
                background-color: $indigo;
            }
        }
        #{$block-name}__title {
            color: $dark;
        }
    }
    &.disabled {
        pointer-events: none;
    }
}
