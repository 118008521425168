@import "variables";
html {
    height: 100%;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul[class],
ol[class] {
    list-style: none;
}

body {
    min-height: 100%;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $avenir-next;
    font-size: 16px !important;
    letter-spacing: 0.44px;
    background: #f8fafc !important;
    #app {
        height: 100%;
        .app {
            height: 100%;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &--stretch-height {
        flex-grow: 1;
    }
}

.main-content {
    $local-light-gray: #f0f0f0;
    $block-name: &;
    flex: 1;
    padding-top: 25px;
    background-color: $white;
    @media all and (min-width: $s){
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    }
    &__text {
        margin-bottom: 20px;
    }
    &--button-bottom-fixed {
        display: flex;
        flex-direction: column;
        .main-content__main {
            padding-bottom: 70px;
        }
        .main-content__bottom {
            position: fixed;
            z-index: 20;
            bottom: 0;
            left: auto;
            right: auto;
            width: 100%;
            max-width: 800px;
            padding: 20px 0;
            background-color: $white;
            &-wrap {
                text-align: center;
            }
        }
    }
    &--offset-bottom {
        padding-bottom: 30px;
    }
    &--no-offset {
        padding: 0;
    }
    &--bg-light-gray {
        background-color: $local-light-gray;
    }
    &--base-layout {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        min-height: 100%;
        padding-top: 15px;
        @media all and (min-width: $s){
            margin: unset;
        }
        .main-content {
            &__top-content-section {
                margin-bottom: 20px;
            }
            &__button-wrap {
                width: 100%;
                padding: 20px 0;
                text-align: center;
            }
            &__buttons-wrap {
                display: flex;
                padding: 20px 0;
                margin: 0 -5px;
                @media all and (min-width: $xs) {
                    margin: 0 -10px;
                }
                &--wrap {
                    @media all and (max-width: $s - 1) {
                        flex-wrap: wrap;
                    }
                    #{$block-name} {
                        &__button-col {
                            @media all and (max-width: $s - 1) {
                                width: 100%;
                                &:not(:last-child) {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
            &__button-col {
                width: 50%;
                padding: 0 5px;
                text-align: center;
                @media all and (min-width: $xs) {
                    padding: 0 10px;
                }
            }
        }
    }
}

.text-center {
    text-align: center;
    margin: auto;
}

.main-layout {
    &--bg-gradient {
        min-height: 100%;
        background: linear-gradient(0deg, rgba(245, 245, 245, 0.2), rgba(245, 245, 245, 0.2)), linear-gradient(345.35deg, rgba(50, 205, 153, 0.2) -0.6%, rgba(50, 205, 153, 0) 89.02%);
    }
}

.w-100 {
    width: 100%;
}

.continue-button {
    width: 100%;
    @media all and (min-width: $xs) {
        max-width: 350px;
    }
}

.el-form-item {
    &__label {
        &--floating-label,
        &--input-mask {
            width: 100%;
        }
    }
}

.el-main {
    padding: 30px 10px 20px;
    @media all and (min-width: $s) {
        padding: 40px 15px 20px;
    }
    @media all and (min-width: $m) {
        padding: 40px 20px 20px;
    }
    &__content {
        &-top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;
            overflow: hidden;
            @media all and (min-width: $s) {
                flex-direction: row;
                justify-content: space-between;
            }
            &-filters {
                @media all and (max-width: $s - 1) {
                    order: 2;
                    width: 100%;
                }
                @media all and (min-width: $s) {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    flex-grow: 1;
                    justify-content: space-between;
                    margin-right: 40px;
                }
            }
        }
        &-breadcrumbs {
            @media all and (max-width: $s - 1) {
                order: 1;
                margin-bottom: 10px;
            }
            @media all and (min-width: $s) {
                display: flex;
                align-items: center;
                min-height: 24px;
                min-width: 90px;
            }
        }
        &-date-picker {
            flex-shrink: 0;
            @media all and (max-width: $m - 1) {
                order: 1;
                margin-bottom: 10px;
            }
            @media all and (min-width: $s) {
                margin-right: 10px;
            }
        }
        &-filter {
            flex-shrink: 0;
            margin-top: -1px;
            @media all and (max-width: $m - 1) {
                order: 2;
                margin-bottom: 10px;
            }
            &--grow {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }
    }
    &__title {
        margin-bottom: 10px;
        text-transform: capitalize;
    }
    &--users-page {
        @media all and (min-width: $m) {
            padding: 30px 15px 20px;
        }
        @media all and (min-width: $m) {
            padding: 40px 30px 20px;
        }
    }
}

.home-container {
    height: calc(100% - 54px);
    background-color: $white;
    @media all and (min-width: $xs) {
        height: calc(100% - 70px);
    }
    @media all and (min-width: $m) {
        max-width: 1920px;
        margin: 0 auto;
    }

    &__main {
        overflow: auto;
        padding: 0 !important;
        @media all and (min-width: $s) {
          overflow: hidden;
        }
    }
    &--mob-scroll {
        @media all and (min-width: $s) {
            max-width: 1920px;
            height: calc(100% - 70px);
            margin: 0 auto;
        }
        .home-container {
            &__main {
                overflow: auto;
                @media all and (min-width: $s) {
                    overflow: hidden;
                    padding: 0 !important;
                }

            }
        }
    }
}

.flex-grow {
    flex-grow: 1;
}
