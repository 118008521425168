.el-collapse {
    $block-name: &;
    &#{$block-name}--lesson {
        #{$block-name} {
            &-item {
                &__header {
                    position: relative;
                    height: unset;
                    padding: 13px 0 15px 30px;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: unset;
                }
                &__arrow {
                    position: absolute;
                    left: 2px;
                }
                &__content {
                    padding-bottom: 8px;
                }
            }
        }
    }
}
