.el-progress {
    $block-name: &;
    &__text {
        height: 100%;
    }
    &--circle {
        #{$block-name} {
            &-circle {
                path.el-progress-circle__track {
                    stroke: $light-gray;
                    stroke-width: 4px;
                }
            }
        }
    }
    &--pmo {
        #{$block-name} {
            &-bar {
                &__outer {
                    height: 10px;
                    border: 1px solid $indigo;
                    border-radius: 4px;
                    background-color: $light-indigo;
                }
                &__inner {
                    border-radius: 0;
                }
            }
        }
    }
    &--sugar-level {
        border: 1px solid $light-gray;
        border-radius: 4px;
        padding: 5px 0;
        overflow: hidden;
        #{$block-name} {
            &-bar {
                position: relative;
                z-index: 3;
                &__outer {
                    border-radius: unset;
                    overflow: visible;
                    background-color: transparent;
                }
                &__inner {
                    z-index: 1;
                    max-width: 104%;
                    border-radius: 0 4px 4px 0;
                    opacity: 0.8;
                    @media all and (min-width: $s) {
                        max-width: 102%;
                    }
                }
            }
            &__text {
                display: block;
                min-width: unset;
                margin-right: unset;
                line-height: unset;
                color: unset;
            }
            &__marker {
                position: absolute;
                z-index: 1;
                top: -5px;
                height: 35px;
                &-line {
                    position: relative;
                    height: 100%;
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                    }
                    &:before {
                        z-index: 1;
                        left: -1px;
                        width: 4px;
                        background-color: $white;
                    }
                    &:after {
                        z-index: 2;
                        left: 0;
                        border-left: 2px dashed $maroon;
                    }
                }
            }
        }
    }
}
