@import "resources/sass/_variables.scss";
.header {
    $block-name: &;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    background-color: #00315D;
    line-height: unset;
    .main-header {
        width: 800px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
    }
    &__right-side {
        display: flex;
    }
    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        &:hover {
            #{$block-name}__menu-icon {
                color: $green;
            }
        }
    }
    &__menu-icon {
        font-size: 18px;
        transition: 0.2s color ease-in-out;
    }
    &__text {
        font-family: $avenir-next;
        font-size: 18px;
        line-height: 1.5;
        color: $white;
    }
    &__user-menu {
        position: fixed;
        z-index: 1201;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        background-color: $white;
        transform: translateX(-300px);
        transition: .3s transform linear;
        &.menu-open {
            transform: translateX(0);
        }
    }
    &__overlay {
        position: fixed;
        z-index: 1200;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        background-color: rgba(0,0,0,50%);
        transition: 0.2s opacity ease-in-out, 0.2s visibility ease-in-out;
        &.menu-open {
            opacity: 1;
            visibility: visible;
        }
    }
}
