.el-checkbox {
    $block-name: &;
    &__inner {
        width: 18px;
        height: 18px;
        border: 2px solid $gray;
        &:after {
            top: 0;
            width: 4px;
            height: 9px;
            border-width: 2px;
        }
    }
    &__label {
        margin-bottom: -3px;
        padding-left: 15px;
        font-size: 16px;
        font-weight: 400;
        color: $dark;
        strong {
            font-weight: 600;
        }
        &-sub {
            &-text {
                font-size: 15px;
                color: $gray;
            }
            &-icon {
                width: 20px;
                height: 20px;
                padding: 0 5px;
                vertical-align: sub;
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
    &__input.is-checked + #{$block-name}__label {
        color: $dark;
    }
    &-group {
        &--column {
            display: flex;
            flex-direction: column;
            #{$block-name} {
                align-items: flex-start;
                height: unset;
                margin-right: 0;
                white-space: normal;
                &:not(:last-child) {
                    margin-bottom: 25px;
                }
                &__label {
                    line-height: 1.5;
                }
                &__input {
                    padding-top: 2px;
                }
            }
            &-middle-offset-bottom {
                #{$block-name} {
                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    &--shop-product-block {
        width: 31px;
        #{$block-name} {
            &__inner {
                background-color: #f0f0f0;
            }
        }
    }
    &--middle-offset-bottom {
        margin-bottom: 15px;
    }
}
