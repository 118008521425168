.el-input {
    $local-red: #ff0000;
    position: relative;
    &__wrapper {
        border-radius: unset;
        padding: 0;
        box-shadow: unset;
        &:hover,
        &.is-focus {
            box-shadow: unset;
        }
    }
    &__inner {
        height: 57px;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $indigo;
        border-radius: 10px 10px 0 0;
        padding: 15px;
        font-family: $avenir-next;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 600;
        letter-spacing: 0.15px;
        color: $black;
        background-color: #e5e8f4;
        transition: 0.2s border-color ease-in-out;
        &::placeholder {
            visibility: visible;
            opacity: 1;
            display: block;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: $indigo;
            transition: 0.2s transform linear, 0.2s font-size linear;
        }
        &:focus {
            &::placeholder {
                font-size: 12px;
                transform: translate(0, -20px);
            }
        }
        &::-ms-reveal,
        &::-ms-clear {
            display: none !important;
        }

    }
    &__label {
        position: absolute;
        left: 15px;
        color: $local-red;
        font-size: 16px;
    }
    &.search-box {
        &__input {
            .el-input__inner {
                height: 48px;
                border-width: 0;
                border-radius: 5px;
                padding: 12px 12px 12px 52px;
                background-color: $white-second;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
                &::placeholder {
                    font-weight: 600;
                    letter-spacing: 0.15px;
                    color: lighten($gray, 25%);
                    transition: 0.2s opacity linear;
                }
                &:focus {
                    &::placeholder {
                        opacity: 0;
                        font-size: 16px;
                        transform: unset;
                    }
                }
            }
        }
    }
    &--floating-label {
        position: relative;
        .el-input {
            &__suffix {
                position: absolute;
                top: 13px;
                left: 15px;
                height: auto;
                font-size: 16px;
                color: $indigo;
                visibility: visible;
                transition: 0.15s visibility linear, 0.15s transform linear, 0.15s font-size linear;
            }
            &__inner {
                padding-left: 15px !important;
                &:placeholder-shown ~ .el-input__suffix,
                &:placeholder-shown ~ .el-select__suffix{
                    visibility: visible;
                }
                &:placeholder-shown:focus ~ .el-input__suffix,
                &:placeholder-shown:focus ~ .el-select__suffix,
                &:not(:placeholder-shown) ~ .el-input__suffix,
                &:not(:placeholder-shown) ~ .el-select__suffix {
                    visibility: visible;
                    font-size: 12px;
                    transform: translate(0, -19px);
                }
            }
        }
    }
    &--input-mask {
        .el-input {
            &__inner {
                padding-left: 15px !important;
                &::placeholder {
                    opacity: 0;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.15px;
                    color: $black;
                    transform: unset;
                    transition: 0.2s opasity ease-in-out;
                }
                &:focus {
                    &::placeholder {
                        opacity: 1;
                        font-size: 16px;
                        transform: unset;
                    }
                }
                &:placeholder-shown:focus ~ .el-input__prefix,
                &:placeholder-shown:focus ~ .el-select__prefix,
                &:not(:placeholder-shown) ~ .el-input__prefix,
                &:not(:placeholder-shown) ~ .el-select__prefix {
                    visibility: visible;
                    font-size: 12px;
                    transform: translate(0, -19px);
                }
            }
            &__prefix {
                position: absolute;
                top: 13px;
                left: 15px;
                height: auto;
                font-size: 16px;
                color: $indigo;
                visibility: visible;
                transition: 0.15s visibility linear, 0.15s transform linear, 0.15s font-size linear;
            }
        }
    }
    &--password {
        .el-input {
            &__prefix {
                position: absolute;
                right: 0;
                cursor: pointer;
                &-icon {
                    width: 18px;
                }
            }
        }
    }
    &--filter {
        .el-input {
            &__inner {
                width: auto;
                flex-grow: 1;
                height: 24px;
                border-width: unset;
                border-color: unset;
                border-radius: unset;
                border-style: unset;
                padding: 0;
                font-size: 12px;
                line-height: 2;
                color: $dark-middle;
                font-weight: 600;
                background-color: unset;
                &::placeholder {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    color: $dark-middle;
                    transition: 0.2s opacity linear;
                }
                &:focus {
                    &::placeholder {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
