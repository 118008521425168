@import "resources/sass/_variables.scss";
.el-table {
    $block-name: &;
    &__header {
        thead tr {
            #{$block-name} {
                &__cell {
                    padding: 14px 0;
                    color: $white;
                    background-color: $indigo;
                    .cell {
                        font-size: 12px;
                        line-height: 1.1;
                    }
                    &:not(:first-child) {
                        .cell {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    &__body {
        tbody {
            tr#{$block-name} {
                &__row{
                    &:first-child {
                        #{$block-name} {
                            &__cell {
                                padding: 10px 0;
                                .cell {
                                    font-weight: bold;
                                    color: $indigo;
                                }
                            }
                        }
                    }
                    #{$block-name} {
                        &__cell {
                            color: $dark-middle;
                            .cell {
                                font-size: 12px;
                                line-height: 1.3;
                            }
                            &:not(:first-child) {
                                .cell {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
    .cell {
        word-break: break-word;
        cursor: default;
        &__link {
            color: $dark-middle;
            transition: 0.25s color ease-in-out;
            &:hover {
                color: $indigo;
            }
        }
    }
    &.is-scrolling-none th.el-table-fixed-column--left,
    &.is-scrolling-left th.el-table-fixed-column--left {
        background-color: $indigo;
    }

    &--companies {
        #{$block-name} {
            &__body {
                tbody {
                    #{$block-name} {
                        &__row {
                            &.pmo_status-row,
                            &.rated_percent-row,
                            &.thumb_up_percent-row,
                            &.thumb_down_percent-row {
                                .el-table__cell {
                                    border-bottom-color: $gray;
                                }
                            }
                            &.replaced-row,
                            &.replaced_per_user-row,
                            &.thumb_up-row,
                            &.thumb_up_per_user-row,
                            &.thumb_down-row,
                            &.thumb_down_per_user-row,
                            &.rated_percent-row,
                            &.thumb_up_percent-row,
                            &.thumb_down_percent-row {
                                .el-table-fixed-column--left {
                                    &.el-table__cell {
                                        z-index: 10;
                                    }
                                }
                                .el-table__cell {
                                    z-index: 4;
                                    &:first-child {
                                        border-left: 1px solid $gray;
                                    }
                                    &:last-child {
                                        border-right: 1px solid $gray;
                                    }
                                }
                            }
                        }
                    }
                }
                &-wrapper {
                    .el-scrollbar__bar {
                        z-index: 10;
                    }
                }
            }
        }
    }
    &--border {
        padding: 0 0 10px;
        &:after,
        &:before {
            height: calc(100% - 10px);
        }
        .el-table__body-wrapper {
            overflow: visible;
            .el-scrollbar {
                height: calc(100% + 10px);
                overflow: visible;
                &__bar {
                    &.is-horizontal {
                        bottom: -7px;
                    }
                }
            }
        }
    }
    &--pmo-status {
        #{$block-name} {
            &__header {
                thead tr {
                    #{$block-name} {
                        &__cell {
                            color: $gray;
                            background-color: $white;
                            .cell {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            &__body {
                tbody {
                    tr#{$block-name} {
                        &__row{
                            &:first-child {
                                #{$block-name} {
                                    &__cell {
                                        .cell {
                                            font-weight: normal;
                                            color: $dark-middle;
                                        }
                                    }
                                }
                            }
                            #{$block-name} {
                                &__cell {
                                    .cell {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
