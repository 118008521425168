.el-button {
    $local-light-gray: #d4d4d4;
    $local-light-indigo: #b5b7df;
    $block-name: &;
    height: 36px;
    font-family: $avenir-next;
    font-size: 18px;
    font-weight: 500;
    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        overflow: hidden;
        &-svg {
            max-width: 100%;
            max-height: 100%;
            transition: 0.2s fill ease-in-out;
        }
        &--no-offset {
            margin-right: 0;
        }
    }
    &--default {
        border-color: $indigo;
        color: $indigo;
    }
    &--primary {
        border-color: $indigo;
        color: $white;
    }
    &--transparent {
        border: 1px solid transparent;
        padding: 6px;
        font-size: 14px;
        text-transform: uppercase;
        color: $indigo;
        background-color: transparent;
        transition: 0.2s border-color ease-in-out;
        &:hover,
        &:active,
        &:focus {
            border-color: $indigo;
            background-color: transparent;
        }
        &.is-disabled {
            border-color: transparent;
            background-color: transparent;
            color: $local-light-indigo;
            &:hover,
            &:active,
            &:focus {
                border-color: transparent;
                background-color: transparent;
                color: $local-light-indigo;
            }
        }
        &-invert-color {
            color: $white;
            &:hover {
                color: $white;
            }
        }
    }
    &--info {
        height: 32px;
        border-color: $local-light-gray;
        font-size: 13px;
        font-weight: 400;
        color: $dark;
        & > span {
            color: $dark;
            transition: 0.2s color ease-in-out;
        }
        &.is-round {
            padding: 6px 10px;
        }
        &:hover, &.active {
            #{$block-name} {
                &__icon-svg {
                    fill: $indigo;
                }
            }
            > span {
                color: $indigo;
            }
        }
    }
    &--small {
        padding: 5px 11px;
        font-size: 14px;
    }
    &.is-circle {
        padding: 8px 7px;
        #{$block-name} {
            &__icon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0;
            }
        }
        &.like {
            border-color: $blue;
            color: $white;
            background-color: $blue;
        }
        &.dislike {
            border-color: $maroon;
            color: $white;
            background-color: $maroon;
        }
    }
}
