.learning-modules {
    display: flex;

    &__between {
        width: 6px;
        height: 100vh;
        background: linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
        cursor: url("/images/svg-icons/ew-resize.svg") 20 20, ew-resize;
    }

    &__left {
        padding: 10px 10px 50px;
        width: 100%;
        height: calc(100vh - $xs-header-height);
        overflow: auto;

        @media all and (min-width: $xs){
            width: 100%;
            height: calc(100vh - $default-header-height);
        }
    }

    &__right {
        padding: 10px;
        width: 100%;
        height: calc(100vh - $xs-header-height);
        overflow: auto;

        @media all and (min-width: $xs){
            height: calc(100vh - $default-header-height);
        }
    }

    &__title {
        margin: 20px 0;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    &__text {
        text-align: center;
        margin-bottom: 20px;
    }
}
