.embedded-video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &--vimeo {
        iframe {
            position: absolute;
            z-index: 2300;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
