.reference-block {
    $block-name: &;
    padding-bottom: 5px;
    &:not(:first-child) {
        padding-top: 3px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
    }
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        color: $dark;
        &:hover {
            #{$block-name}__title-icon {
                color: $green;
            }
        }
        &-text {
            flex-grow: 1;
            font-size: 16px;
            font-weight: 600;
        }
        &-icon {
            flex-shrink: 0;
            margin-left: 10px;
            font-size: 20px;
            transition: 0.2s color ease-in-out;
        }
    }
    &__description {
        margin-bottom: 3px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: normal;
        color: darken($light-gray, 37%);
    }
    &__source {
        font-size: 15px;
        text-decoration: none;
        color: lighten($blue, 40%);
        transition: 0.2s color ease-in-out;
        &:hover {
            color: $green;
        }
    }
}
