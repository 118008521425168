.el-calendar {
    $block-name: &;
    $local-light-gray: #dddddd;
    &__header {
        border-color: $local-light-gray;
        padding: 15px 0;
        &-inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media all and (max-width: $s - 1) {
                flex-wrap: wrap;
            }
        }
    }
    &__body {
        padding: 12px 0 35px;
    }
    &-table {
        thead {
            th {
                padding: 15px 0;
                font-size: 12px;
                font-weight: 600;
                line-height: 1.35;
                text-align: center;
                color: $dark-middle;
            }
        }
        tbody {
            #{$block-name} {
                &-table {
                    &__row {
                        td {
                            border-color: $local-light-gray;
                            #{$block-name} {
                                &-day {
                                    font-size: 12px;
                                    line-height: 1.33;
                                    text-align: right;
                                    color: $dark-middle;
                                }
                            }
                            &.is-selected {
                                #{$block-name} {
                                    &-day {
                                        background-color: $green;
                                        font-weight: 600;
                                        color: white;
                                    }
                                }
                            }
                            &.next,
                            &.prev {
                                #{$block-name} {
                                    &-day {
                                        color: $light-gray;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__date {
        display: flex;
        align-items: center;
        @media all and (max-width: $s - 1) {
            margin-right: 15px;
        }
        @media (min-width: $s) and (max-width: $l - 1) {
            margin-right: 20px;
        }
        @media all and (min-width: $l) {
            margin-right: 40px;
        }
        &-icon {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            &-svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-value {
            margin-bottom: -3px;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.35;
            white-space: nowrap;
            color: $dark-middle;
        }
        &-wrap {
            display: flex;
            margin-right: 20px;
        }
    }
    &__controls {
        white-space: nowrap;
        .el-button + .el-button {
            @media all and (max-width: $s - 1) {
                margin-right: 10px;
            }
            @media all and (min-width: $s) {
                margin-left: 20px;
            }
        }
    }
    &__filter {
        &:not(:last-child) {
            @media all and (min-width: $s) and (max-width: $l - 1) {
                margin-right: 20px;
            }
            @media all and (min-width: $l) {
                margin-right: 40px;
            }
        }
    }
}
