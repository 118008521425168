.el-dialog {
    $block-name: &;
    @media screen and (max-width: $s - 1) {
        width: 100%;
        border-radius: 0;
    }
    &__header {
        padding: 20px 55px 10px 24px;
    }
    &__headerbtn {
        #{$block-name}__close {
            width: 30px;
            height: 25px;
            color: $dark;

            svg.icon {
                width: 25px;
                height: 25px;
            }
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 600;
        color: $dark;
    }
    &__body {
        padding: 10px 24px 10px;
        word-break: break-word;
    }
    @media (max-width: $xs - 1) {
        .el-form-item__label {
            width: auto !important;
        }
        .el-form-item__content {
            clear: both;
            margin-left: 0 !important;
        }
    }
    &--stores-list {
        width: 90%;
        @media all and (min-width: $s) {
            width: 600px;
        }
        .el-dialog {
            &__body {
                &-text {
                    margin-bottom: 15px;
                }
            }
        }
    }
    &--share-shopping-list {
        width: 340px;
        @media all and (min-width: $s) {
            width: 480px;
        }
        .el-dialog {
            &__body {
                padding: 10px 24px 30px;
            }
        }
    }
    &--indigo-bg {
        width: 90%;
        border-radius: 15px;
        background-color: $indigo;
        @media all and (min-width: $s) {
            width: 550px;
        }
        #{$block-name} {
            &__body {
                word-break: break-word;

                &-text {
                    color: $white;
                }
            }
        }
    }
    &--without-header {
        #{$block-name} {
            &__header {
                display: none;
            }
            &__body,
            &__footer {
                padding: 10px 20px;
            }
        }
    }
    &--learning-module {
        width: 360px;
        border-radius: 5px;
        #{$block-name} {
            &__header {
                display: none;
            }
            &__body {
                padding: 30px 25px;
            }
            &__footer {
                padding: 0;
            }
        }
    }
    &--phone-number {
        width: 400px;
        max-width: 80%;
    }
    &--dislike-product {
        width: 90%;
        @media all and (min-width: $s) {
            width: 540px;
        }

        #{$block-name} {
            &__body {
                padding: 10px 25px 25px;
            }
        }
    }
    &--full-screen-video {
        background-color: $black;
        #{$block-name} {
            &__header {
                display: none;
            }
            &__body {
                padding: 0;
            }
        }
    }
    &--welcome-popup {
        #{$block-name} {
            &__header {
                margin-right: 0;
                @media all and (max-width: $m - 1) {
                    padding: 30px 20px 5px;
                }
                @media all and (min-width: $m) {
                    padding: 30px 40px 10px;
                }
            }
            &__body {
                @media all and (max-width: $m - 1) {
                    padding: 5px 20px 20px;
                }
                @media all and (min-width: $m) {
                    padding: 10px 40px 40px;
                }
            }
        }
    }
}
