.el-form-item {
    &__error:first-letter {
        text-transform: capitalize
    }
    &--desktop-no-offset {
        @media all and (min-width: 992px) {
            margin: 0;
        }
    }
}
.el-form-item.is-error .el-input__wrapper {
    box-shadow: 0 0 0 4px var(--el-color-danger) inset!important;
}
