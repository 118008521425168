.accordion {
    $block-name: &;
    &__item {
        border-top: 1px solid $light-gray;
        padding: 15px 0;
        &-header {
            display: flex;
            align-items: center;
            cursor: pointer;
            &-icon {
                flex-shrink: 0;
                margin-right: 15px;
                font-size: 14px;
                color: $gray;
                transition: 0.2s color ease-in-out, 0.3s transform ease-in-out;
            }
            &-title {
                flex-grow: 1;
                font-weight: 600;
                color: $dark;
            }
            &.open {
                #{$block-name}__item-header-icon {
                    color: $dark;
                    transform: rotate(-180deg);
                }
            }
        }
        &-body {
            padding-top: 15px;
            &-text {
                margin-bottom: 15px;
            }
        }
    }
}
