.profile-info {
    &__form {
        &-section {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
            &-item {
                margin-bottom: 28px;
            }
            &-title {
                margin-bottom: 15px;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.4;
                letter-spacing: 0.15px;
                color: $dark;
            }
            &-text {
                margin-bottom: 15px;
                &.default-text {
                    p {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
