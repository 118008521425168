.el-radio {
    $block-name: &;
    height: unset;
    margin-right: unset;
    white-space: normal;
    &__input {
        &.is-checked {
            #{$block-name} {
                &__inner {
                    background-color: transparent;
                }
            }
            & + #{$block-name}__label {
                color: $dark;
            }
        }
    }
    &__inner {
        width: 20px;
        height: 20px;
        border: 2px solid $gray;
        box-shadow: none;
        &:after {
            width: 8px;
            height: 8px;
            background-color: $indigo;
        }
    }
    &__label {
        padding-left: 26px;
        font-size: 16px;
        font-weight: 400;
        color: $dark;
        strong {
            font-weight: 600;
        }
        &-sub {
            &-text {
                font-size: 15px;
                color: $gray;
            }
            &-icon {
                width: 20px;
                height: 20px;
                padding: 0 5px;
                vertical-align: sub;
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

    }
    &:focus:not(.is-focus):not(:active):not(.is-disabled) {
        #{$block-name}__inner {
            box-shadow: none;
        }
    }
    &-group-bordered {
        &.radio-block {
            #{$block-name} {
                display: flex;
                align-items: flex-start;
                flex-grow: 1;
                height: unset;
                margin-right: 0;
                border: 1px solid transparent;
                border-radius: 24px;
                padding: 12px 18px;
                line-height: 1.5;
                white-space: normal;
                overflow: hidden;
                outline: none;
                transition: 0.2s border-color ease-in-out, 0.2s background-color ease-in-out;
                &__input {
                    padding-top: 2px;
                }
                &.is-checked {
                    border: 1px solid $indigo;
                    border-radius: 24px;
                    background-color: #eef1ff;
                    #{$block-name}__inner {
                        background: $white;
                    }
                    #{$block-name}__label {
                        color: $dark;
                    }
                }
            }
        }
    }

    &.is-bordered {
        justify-content: space-between;
        width: 100%;
        height: unset;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
        padding: 15px 15px 15px 42px;
        white-space: normal;
        background-color: #f4f4f4;
        transition: 0.2s border ease-in-out, 0.2s background-color ease-in-out;
        &:before {
            content: "";
            position: absolute;
            width: 22px;
            height: 16px;
            top: 10px;
            left: 9px;
            background-image: url("/images/quiz/quotes-icon.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:not(:last-child) {
            margin: 0 0 15px;
        }
        #{$block-name} {
            &__input {
                order: 2;
                margin-left: 10px;
                opacity: 0;
                transition: 0.2s opacity ease-in-out;
                #{$block-name}__inner {
                    border: unset;
                    background-color: transparent;
                    transition: 0.2s border ease-in-out, 0.2s background-color ease-in-out;
                    &:after {
                        background-color: transparent;
                        border-color: transparent;
                        transform: rotate(45deg) scaleY(0.7);
                        transform-origin: center;
                        transition: transform 0.2s ease-in 0.05s;
                    }
                }
                &.is-checked {
                    opacity: 1;
                    #{$block-name}__inner {
                        border: 1px solid $indigo;
                        background-color: $indigo;
                        &:after {
                            top: 2px;
                            left: 6px;
                            width: 6px;
                            height: 11px;
                            border: 2px solid $white;
                            border-left: 0;
                            border-top: 0;
                            border-radius: unset;
                            transform: rotate(45deg) scaleY(1);
                        }
                    }
                }
            }
            &__label {
                order: 1;
                padding: 0;
                font-weight: 600;
                color: $dark;
            }
        }
        &.is-checked {
            background-color: $light-indigo;
        }
    }

    &-group {
        &.quiz-rate {
            margin: 0 -1px;
            @media all and (min-width: $xs) {
                margin: 0 -2px;
            }
            @media all and (min-width: $s) {
                margin: 0 -5px;
            }
            #{$block-name} {
                &-button {
                    flex-grow: 1;
                    flex-shrink: 0;
                    padding: 0 1px;
                    &:focus:not(.is-focus):not(:active):not(.is-disabled) {
                        box-shadow: none;
                    }
                    @media all and (min-width: $xs) {
                        padding: 0 2px;
                    }
                    @media all and (min-width: $s) {
                        padding: 0 5px;
                    }
                    &__original-radio:checked {
                        & + .el-radio-button__inner {
                            background-color: $indigo;
                            color: $white;
                            box-shadow: none;
                        }
                    }
                    &__inner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        color: $black;
                        border-radius: 5px;
                        padding: 4px 5px;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1.6;
                        background: $light-gray;
                    }
                }
            }
        }
        &__item {
            margin: 0 0 25px;
            &-checkbox-group {
                padding: 20px 0 0 46px;
            }
            &--inner-content {

            }
        }
        &--column {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &--inline {
        flex-direction: column-reverse;
        height: unset;
        margin: 0;
        padding: 0 3px;
        @media all and (min-width: 360px) {
            padding: 0 5px;
        }
        #{$block-name} {
            &__label {
                margin-bottom: 5px;
                padding: 0;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.6;
                color: $gray;
                @media all and (min-width: 360px) {
                    font-size: 12px;
                }
                @media all and (min-width: $xs) {
                    font-size: 14px;
                }
                @media all and (min-width: $s) {
                    font-size: 16px;
                }
            }
            &__input {
                &.is-checked {
                    #{$block-name}__inner {
                        background-color: $white;
                    }
                }
            }
            &__inner {
                border-color: $light-gray;
            }
        }
    }
    &--align-top {
        align-items: flex-start;
        #{$block-name} {
            &__input {
                padding-top: 2px;
            }
        }
    }
}

.el-radio-group {
    &--separated {
        display: block;
        .el-radio {
            display: flex;
            flex-grow: 1;
            height: unset;
            margin: 0;
            border-top: 1px solid $light-gray;
            padding: 10px;
            white-space: normal;
            &:last-child {
                border-bottom: 1px solid $light-gray;
            }
            &__input {
                &.is-checked {
                    .el-radio__inner {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &--inline {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 -5px;
    }
}
