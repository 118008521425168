.el-tab-pane {
    $block-name: &;
    &__content {
        font-size: 15px;
        line-height: 1.6;
        color: $dark;
    }
    &__list {
        &-name {
            margin-bottom: 7px;
        }
        &-row {
            margin-bottom: 9px;
        }
    }
}
